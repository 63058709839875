import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: ${props => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;

  div {
    > ul {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .padded-section {
    .section-label {
      margin-bottom: 5px;
    }

    > div + div {
      margin-top: 30px;
    }
  }
`

const FAQ = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaq(
        filter: { type: { eq: "Incentive Trip" } }
        sort: { fields: order }
      ) {
        edges {
          node {
            id
            title
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)

  const FAQs = data.allContentfulFaq.edges

  return (
    <Wrapper className="wrap">
      <section className="padded-section body-content">
        {FAQs.map(({ node: QAndA }) => (
          <div key={QAndA.id}>
            <h4 className="section-label">{QAndA.title}</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: QAndA.body.childMarkdownRemark.html,
              }}
            />
          </div>
        ))}
      </section>
    </Wrapper>
  )
}

export default FAQ
